<template>
    <div class="">
        <SalesAndBusinessBI :criterias="criterias" :action="action" :viewType="viewType" />
    </div>
</template>

<script>
import SalesAndBusinessBI from '../global/salesAndBusinessBI.vue'
import appConfig from '@/app.config'
export default {
    page: {
        title: 'Business chasis reports',
        meta: [{ name: 'description', content: appConfig.description }],
    },
    components: {
        SalesAndBusinessBI,
    },
    data() {
        return {
            action: 'businessChasis',
            viewType: "business_chasis",
            criterias: [
                {
                    value: 'leads_monthly',
                    name: 'Leads Monthly (Customer)',
                    showTable: false,
                },
                {
                    value: 'new_top_ups_sms',
                    name: 'New Customer Top Ups-SMS',
                    showTable: false,
                },
                {
                    value: 'new_top_ups_voice',
                    name: 'New Customer Top Ups-VOICE',
                    showTable: false,
                },
                {
                    value: "existing_top_ups_sms",
                    name: 'Existing Customer Top Ups-SMS',
                    showTable: false,
                },
                {
                    value: "existing_top_ups_voice",
                    name: 'Existing Customer Top Ups-VOICE ',
                    showTable: false,
                },
                {
                    value: "total_transaction_sms",
                    name: 'Total Transaction-SMS',
                    showTable: false,
                },
                
                
                {
                    value: 'total_transaction_voice',
                    name: 'Total Transaction-VOICE',
                    showTable: false,
                },
                {
                    value: 'revenue',
                    name: 'Revenue',
                    showTable: false,
                },
               

            ],
            displayData: ''
        }
    }
}
</script>

<style></style>